import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Decedent",
      content: (
        <>
          <p>A decedent is a person who has passed away.</p>
        </>
      ),
    },
    {
      title: "Executor",
      content: (
        <>
          <p>
            An executor is a personal representative appointed by the court to
            administer an estate when someone dies with a will. The executor
            must be named in the will.
          </p>
        </>
      ),
    },
    {
      title: "Gross Estate",
      content: (
        <>
          <p>
            Gross estate refers to the value of a person’s assets at the time of
            their death without taking encumbrances into consideration.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Estate Tax Returns | San Diego | Brierton, Jones & Jones"
        description="Do you need help with your estate tax returns in San Diego? Brierton, Jones & Jones, LLP connects you with what you need. Schedule a consultation today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-12 pb-6 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  ESTATE TAX PREPARATION
                </div>
                <h1>San Diego Estate Tax Return Experts</h1>
                <p>
                  At Brierton, Jones & Jones, LLP, our attorneys are experienced
                  in preparing IRS estate tax returns. This includes estates
                  with significant value where a tax return is required and
                  moderate estates that do not require filing but for which an
                  estate tax return might be advised.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/11.0 Estate Tax Returns/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>When To File an Estate Tax Return</h2>
                <p>
                  Under current law, if the value of a decedent’s gross estate
                  (plus prior taxable gifts) at the time of their death has a
                  total value of less than $13,610,000, then a federal estate
                  tax return is not required.
                </p>
                <p>
                  Even if an estate does not meet the federal threshold for
                  filing, a surviving spouse may want to file an estate tax
                  return to preserve any unused portion of their spouse’s
                  federal estate tax exclusion. The concept of preserving the
                  unused portion of a deceased spouse’s federal estate tax
                  exclusion is known as “portability.” Electing portability, if
                  available, is a technique to decrease the likelihood that
                  estate tax will be due upon the surviving spouse’s death.
                </p>
                <p>
                  Our estate tax return attorneys can advise you on whether a
                  Qualified Terminable Interest Trust (QTIP) election can and
                  should be made. This affects what assets are considered part
                  of the surviving spouse’s estate for estate and income tax
                  purposes.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>Paying an Estate Tax Liability</h2>
                <p>
                  Estate taxes are typically due within nine months after the
                  decedent’s death and are paid by the trustee, executor, or
                  administrator before distributions are made to heirs or
                  beneficiaries.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  Here are some of the common terms when it comes to estate tax
                  returns.
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={10} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="Meet with one of San Diego estate tax return attorneys if you have questions or want more information." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/11 Estate Tax Returns.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Twitter/11 Estate Tax Returns.jpg"
      }
    ) {
      publicURL
    }
  }
`;

export default Page;
